import React from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom'; // Usamos react-router para manejar la navegación

function App() {
  const navigate = useNavigate(); // Hook para navegar entre vistas

  const goToPlan = () => {
    navigate('/plan'); // Esta función redirige a la vista del plano
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Bienvenido a la Casa Domótica</h1>
        <p>
          Este es el sistema de control de tu casa inteligente.
        </p>
        <button onClick={goToPlan} className="App-button">
          Ver Plano de la Casa
        </button>
      </header>
    </div>
  );
}

export default App;
